import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";

import LocalMallIcon from "@mui/icons-material/LocalMall";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TruckMarker from "./assests/truckMarker.png";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { Typography, Grid, Box, Button } from "@mui/material";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import "./assests/css/track.css";
import { useJsApiLoader, GoogleMap, Marker, DirectionsRenderer } from "@react-google-maps/api";
import axios from "axios";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(62 178 233) 0%,rgb(62 178 233) 50%,rgb(62 178 233) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(62 178 233) 0%,rgb(62 178 233) 50%,rgb(62 178 233) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(62 178 233) 0%, rgb(62 178 233) 50%, rgb(62 178 233) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(62 178 233) 0%, rgb(62 178 233) 50%, rgb(62 178 233) 100%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <LocalMallIcon />,
    2: <WarehouseIcon />,
    3: <LocalShippingIcon />,
    4: <CheckCircleIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = [
  "Consignment Booked",
  "In Transit",
  "Out For Delivery",
  "Deliverd Successfully",
];

function TrackOrder(props) {
  const [statusCount, setStatusCount] = useState(0);
  const [trackingData, setTrackingData] = useState(props.details);
  const [count, setCount] = useState(1);
  const [distance, setDistance] = useState('');
  const [duration, setDuration] = useState('');
  const [map, setMap] = useState( /** @type google.maps.Googlemap */(null))
  const [directions, setDirections] = useState("");
  const { isLoaded, loadError } = useJsApiLoader({ googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY });

  const getGeoCodeAddress = async (address) => {
    try {
      const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
        params: {
          address: address,
          key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
        }
      });

      if (response.data.status === 'OK') {
        const location = response.data.results[0].geometry.location;
        const data = trackingData;
        data.develiveryLatLng = { lat: `${location.lat}`, lng: `${location.lng}` };
        setTrackingData(data);
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error fetching geocode:', error);
    }
  };

  const fetchDirections = async (tracking) => {
    setCount(0);
    const startingPoint = { lat: parseFloat(tracking?.trip?.vehicle?.liveVehicle?.latitude), lng: parseFloat(tracking?.trip?.vehicle?.liveVehicle?.longitude) };
    const endingPoint = { lat: parseFloat(tracking?.develiveryLatLng.lat), lng: parseFloat(tracking?.develiveryLatLng.lng) };
    const waypoints = [];
    let data = {
      origin: startingPoint,
      destination: endingPoint,
      waypoints: waypoints,
      travelMode: window.google.maps.TravelMode.DRIVING,
    }
    if (isLoaded) {
      const directionsService = new window.google.maps.DirectionsService();
      const rendererOptions = {
        map: map,
        suppressMarkers: true
      };
      const directionsDisplay = new window.google.maps.DirectionsRenderer(rendererOptions);
      await directionsService.route(
        data,
        (result, status) => {
          if (status === "OK" && result) {
            var myRoute = result.routes[0].legs[0];
            setDistance(myRoute.distance.text);
            setDuration(myRoute.duration.text);
            var marker = new window.google.maps.Marker({
              position: myRoute.steps[0].start_location,
              map: map,
              icon: {
                url: TruckMarker,
                scaledSize: new window.google.maps.Size(60, 60)
              }
            });
            // setDirections(result);
          }
        }
      );
    }
  };


  const showDirections = async () => {
    await getGeoCodeAddress(trackingData?.address);
    await fetchDirections(trackingData);
  }

  // const consigneeDetails = JSON.parse(props.details[0].consigneeDetails);
  // const consignorDetails = JSON.parse(props.details[0].consignorDetails);

  // const dataToSort = props.details;

  // const sortByDate = (arr) => {
  //   const sorter = (a, b) => {
  //     return (
  //       new Date(a.StatusDatetime).getTime() -
  //       new Date(b.StatusDatetime).getTime()
  //     );
  //   };
  //   arr.sort(sorter);
  // };
  // sortByDate(dataToSort);



  useEffect(() => {
    const lastStatus = trackingData?.trip?.tripStatus;
    if (lastStatus == "Trip Ended") {
      setStatusCount(3);
    }

    if (lastStatus == "Trip Started") {
      setStatusCount(2);
    }
    if (lastStatus == "Yet To Start") {
      setStatusCount(1);
    }
  }, []);

  if (count == 1) {
    showDirections();
  }

  return (
    <>
      <Box className="track-desktop">
        <Stack sx={{ width: "100%" }} spacing={4}>
          <Stepper
            alternativeLabel
            activeStep={statusCount}
            connector={<ColorlibConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>

        <Grid
          container
          spacing={1}
          sx={{
            margin: "auto",
            paddingTop: "2%",
            paddingLeft: "7%",
            paddingBottom:'2%'
          }}
        >

          <Grid item xs={12} style={{ marginLeft: "2%", display: 'none' }}>
            <Button variant="contained" color="primary" onClick={showDirections}>
              Show Directions
            </Button>
          </Grid>

          <Grid item xs={4} >
            <Grid container >
              <Grid item xs={12} >
                <b>Delivery Address:</b> <br />{trackingData?.address}
              </Grid>
              <Grid item xs={12} mt={1} >
                <b>Estimated Delivery Time:</b><br /> {duration}
              </Grid>
              <Grid item xs={12} mt={1} >
                <b>Distance Remaining:</b> <br />{distance}
              </Grid>
            </Grid>
          </Grid>



          {/* <Grid item xs={12} style={{ marginLeft: "2%" }}>
            Live Tracking:<b> {trackingData?.trip?.vehicle?.liveVehicle?.vehicleNumber}</b>
          </Grid> */}
          <Grid item xs={7} style={{margin:'auto'}} >
            {isLoaded && (
              <GoogleMap
                zoom={12}
                center={{ lat: trackingData?.trip?.vehicle?.liveVehicle?.latitude, lng: trackingData?.trip?.vehicle?.liveVehicle?.longitude }}
                mapContainerStyle={{ width: "88%", height: "42vh", padding: "1px" }}
                options={{
                  zoomControl: true,
                  mapTypeId: "roadmap",
                  // streetViewControl: true,
                  mapTypeControl: true,
                  fullscreenControl: true,
                  smoothScroll: true, // added smooth scroll
                }}
                onLoad={map => setMap(map)}
              >
                {directions && (
                  <DirectionsRenderer
                    directions={directions}
                    options={{
                      // icon: {
                      //   scaledSize: new window.google.maps.Size(35, 35)
                      // },
                      polylineOptions: {
                        zIndex: 50,
                        strokeColor: "#1976D2",
                        strokeWeight: 5,
                      },
                    }}
                  />
                )}
                {trackingData?.trip?.vehicle?.liveVehicle?.latitude && trackingData?.trip?.vehicle?.liveVehicle?.longitude && (
                  <Marker
                    position={{ lat: trackingData?.trip?.vehicle?.liveVehicle?.latitude, lng: trackingData?.trip?.vehicle?.liveVehicle?.longitude }}
                    options={{
                      icon: {
                        url: TruckMarker,
                        scaledSize: new window.google.maps.Size(65, 65)
                      }
                    }}
                  />
                )}

                {/* <Marker
                  position={{ lat: markData?.latitude, lng: markData?.longitude }}
                  options={{
                    icon: {
                      url: TruckMarker,
                      scaledSize: new window.google.maps.Size(35, 35)
                    }
                  }}
                  onClick={() => setVehicleData(markData)}
                /> */}
              </GoogleMap>
            )}
          </Grid>


        </Grid>

      </Box>
      <Box className="track-mobile-view">
        <Stack sx={{ width: "100%" }} spacing={4}>
          <Stepper
            alternativeLabel
            activeStep={statusCount}
            connector={<ColorlibConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>

      </Box>
    </>
  );
}

export default TrackOrder;
